import React from "react";

import Layout from "@common/Layout";

import { Container } from "@components/global";
import { Link } from "gatsby";
import Navbar from "../components/common/Navbar/Navbar";
import styled from "styled-components";

const NotFoundPage = () => (
  <Layout>
    <Navbar hideItems isImprint />
    <ContentContainer>
      <h2>404 - Diese Seite existiert nicht.</h2>
      <Link to="/">Zurück zu Hauptseite.</Link>
    </ContentContainer>
  </Layout>
);

const ContentContainer = styled(Container)`
  margin-top: 5rem;
  width: 60%;
`;

export default NotFoundPage;
